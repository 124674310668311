import styled, { css } from 'styled-components';
import React, { useCallback, useState, useRef, useContext, useEffect } from 'react';
import { useTranslation } from '@marty-js/design/src/utils/translation';
import { DEVICE_SIZE, mq } from '@marty-js/design/src/utils/mq';
import dynamic from 'next/dynamic';
import { useThemeSwitcher } from '@marty-js/design/src/utils/theme-switcher';
import { Cta } from '@marty-js/design/src/molecules/cta';
import { NavLink } from '../../atoms/nav-link';
import { formatNavLinksFromComponent } from '../../utils/headerFormatter';
import { PrimaryNavItems } from './primary-nav-items';
import { useSdkConfig } from '../../utils/config';
import { PrimarySubNav } from './primary-sub-nav';
import { UserProfile } from './user-profile';
import { AuthContext } from '../../utils/AuthContext';

const BurgerMenu = dynamic(() => import('@marty-js/design/src/icons/burgerMenu'));
const Clubic = dynamic(() => import('@marty-js/design/src/icons/clubic'));
const Close = dynamic(() => import('@marty-js/design/src/icons/close'));
const Moon = dynamic(() => import('@marty-js/design/src/icons/moon'));
const Search = dynamic(() => import('@marty-js/design/src/icons/search'));

const Container = styled.header`
  background-color: var(--theme-palette-red);
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  background-color: var(--theme-color-background);
  transition: background-color 1s;
  z-index: 1000002;

  ${(props) =>
    props.className === 'visible' &&
    mq.lte(
      DEVICE_SIZE.LARGE,
      css`
        top: 0;
        transition: top 0.4s ease-out;
      `,
    )}

  ${(props) =>
    props.className === 'hidden' &&
    mq.lte(
      DEVICE_SIZE.LARGE,
      css`
        top: -55px;
        transition: top 0.4s ease-out;
      `,
    )}

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      background-color: none;
      position: initial;
      justify-content: center;
    `,
  )}
`;

const NavBar = styled.div`
  background: #ff0037;
  color: var(--theme-color-title);
  padding: 0 var(--spacer-s);
  width: 100%;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      background: none;

      &.mod-high {
        padding-bottom: 40px;
      }
    `,
  )}
`;

const TopNav = styled.div`
  align-items: center;
  display: flex;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      justify-content: initial;
      margin: auto;
      max-width: 1250px;
      padding: 15px 0;
      height: 71px;
    `,
  )}
`;

const SubNav = styled.ul`
  align-items: center;
  flex-basis: 100%;
  justify-content: center;
  left: 0;
  line-height: 1;
  padding: 0;
  width: 100%;

  > ul {
    margin: auto;
    max-width: 1200px;
  }

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      background-color: var(--theme-palette-dark-grey);
      color: white;
      display: flex;
      height: 40px;
      position: absolute;
      text-align: center;
      top: 72px;
    `,
  )}
`;

const NavLinks = styled.nav`
  background-color: var(--theme-color-background);
  bottom: 0;
  left: 0;
  overflow-y: auto;
  position: fixed;
  top: 45px;
  right: 0;
  z-index: 1;
  transform: translateX(-100%);

  &.mod-display {
    display: flex;
    flex-direction: column;
    padding: var(--spacer) var(--spacer-s);
    transform: translateX(0%);
  }

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      display: flex;
      flex-direction: row;
      margin: 0 var(--spacer-s);
      overflow: initial;
      position: initial;
      transform: initial;
      z-index: 10;

      > *:nth-child(2) {
        height: 0;
        overflow: hidden;
        transform: translate(-100%);
        width: 0;
      }

      &.mod-display {
        flex-direction: initial;
        padding: 0;
        transform: initial;

        > *:nth-child(2) {
          background: var(--theme-background-contrast-background-color);
          bottom: 0;
          box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.38);
          height: initial;
          position: fixed;
          left: 0;
          display: flex;
          flex-direction: column;
          overflow-y: auto;
          padding: var(--spacer-l) var(--spacer-s) 0;
          transform: translate(0%);
          transition: transform 0.3s;
          width: 20%;
          top: 0;
          z-index: 1000;
        }
      }
    `,
  )}
`;

const DarkModeButton = styled.button`
  align-items: center;
  border: solid 2px var(--text-color-alt);
  border-radius: 50%;
  display: flex;
  height: 40px;
  position: fixed;
  left: calc(20% - 55px);
  z-index: 10000;
  justify-content: center;
  padding: var(--spacer-xs);
  transition: background-color 0.3s, opacity 0.3s, left 0.3s;
  width: 40px;

  path {
    transition: fill 0.3s;
  }

  &.mod-hidden {
    left: -40px;
    transition: initial;
  }

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      border-color: white;
      height: 26px;
      left: initial;
      margin: 0 var(--spacer-xs) 0 0;
      padding: 0;
      position: relative;
      top: -45px;
      width: 26px;

      path {
        fill: white;
        stroke: white;
      }

      &.mod-hidden {
        left: 0;
        position: absolute;
        right: 45px;
        left: initial;
        top: initial;
        opacity: 1;
      }
    `,
  )}

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      path {
        fill: white;
        stroke: white;
      }

      &:hover {
        background-color: var(--text-color-alt);

        path {
          fill: var(--theme-background-contrast-background-color);
          stroke: var(--theme-background-contrast-background-color);
        }
      }
    `,
  )}
`;

const CloseContainer = styled.button`
  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      position: fixed;
      left: var(--spacer-s);
      z-index: 1000;
    `,
  )}

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      display: flex;

      path {
        fill: white;
      }
    `,
  )}
`;

const BurgerContainer = styled.button`
  margin: 0 var(--spacer-s) 0 0;

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      margin: initial;

      path {
        fill: white;
      }
    `,
  )}
`;

const RootLogoLink = styled(NavLink)`
  display: flex;
  margin: 0 auto;
  text-decoration: none;
  position: relative;

  svg {
    border-radius: 6px;
    width: 88px;
    height: 45px;
  }

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      margin: 0;

      &.mod-fixed {
        position: fixed;
        top: var(--spacer-s);
        left: 50px;
        z-index: 100;

        svg {
          width: 55px;
          height: 33px;
        }
      }

      svg {
        width: 82px;
        height: 44px;
      }
    `,
  )}
`;

const UserIcon = styled.div`
  display: flex;

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      &.mod-hidden {
        position: relative;
        top: -45px;
      }
    `,
  )}
`;

const SearchForm = styled.form`
  display: flex;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      background-color: ${(props) => (props.theme.isDark ? '#1f1f1f' : '#e5e8ee')};
      border-radius: 30px;
      margin: 0 var(--spacer-m-fluid);
      width: 100%;
    `,
  )}

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      position: absolute;
      left: 45px;
      top: 11px;

      &.mod-hidden {
        display: none;
      }
    `,
  )}
`;

const SearchFormInput = styled.input`
  background: none;
  border: none;
  border-radius: 30px;
  min-height: 45px;
  padding: 0 var(--spacer-fluid);
  width: 100%;

  &:focus {
    outline: none;
  }

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      display: none;
    `,
  )}
`;

const Validate = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--spacer-s) 0 0;

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      path {
        fill: white;
      }
    `,
  )}
`;

const MenuCta = styled(Cta)`
  white-space: nowrap;
  order: 0;

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      margin: 0 0 var(--spacer-s);
    `,
  )}
`;

interface Props {
  underHeaderComponent: any;
  headerComponent: any;
}

let hasStickyTopClass: boolean;
let stickyAdExists: boolean;
let menu: any;
const positionStickyAd = () => {
  if (hasStickyTopClass && stickyAdExists) {
    let menuHeight = menu?.getBoundingClientRect().height + menu?.getBoundingClientRect().top;

    const topBanner = document.getElementById('top-banner');
    let topHeight = menu.clientHeight;
    if (topBanner && topBanner?.clientHeight) {
      topHeight = topBanner.clientHeight + menu.clientHeight;
    }
    if (menuHeight <= 25) {
      menuHeight = 0;
    }
    if (document.documentElement.scrollTop < topHeight && topBanner) {
      menuHeight += 32;
    }

    document.getElementById('opd_bottomstickyad').style.setProperty('top', `${menuHeight}px`, 'important');
  }
};

if (typeof window !== 'undefined') {
  document.addEventListener('DOMContentLoaded', () => {
    menu = document?.getElementById('nav-primary');
    const observer = new MutationObserver((mutations, obs) => {
      mutations.forEach(() => {
        hasStickyTopClass = document.getElementsByClassName('odstickytop').length > 0;
        stickyAdExists = document.getElementById('opd_bottomstickyad') !== null;

        if (hasStickyTopClass && stickyAdExists && menu?.getBoundingClientRect().height > 0) {
          positionStickyAd();
          obs.disconnect();
        }
      });
    });

    const targetNode = document.body;
    const config = {
      childList: true,
      subtree: true,
    };

    observer.observe(targetNode, config);
  });
}

export const Header: React.FC<Props> = ({ headerComponent, underHeaderComponent }) => {
  const t = useTranslation();
  const { avatar, username } = useContext(AuthContext);

  const sdkConfig = useSdkConfig();

  const navLinks = formatNavLinksFromComponent(headerComponent) ?? [];
  const subNavLinks = formatNavLinksFromComponent(underHeaderComponent) ?? [];

  const [isMenuDisplayed, setIsMenuDisplayed] = useState<boolean>(false);
  const navRef = useRef(null);

  const displayMenu = useCallback(() => {
    setIsMenuDisplayed(true);
    navRef.current.scroll({ top: -navRef.current.scrollHeight });
  }, []);

  const hideMenu = useCallback(() => {
    setIsMenuDisplayed(false);
  }, []);

  const [visible, setVisible] = useState(true);
  const [position, setPosition] = useState(null);

  useEffect(() => {
    setPosition(window.pageYOffset || document.documentElement.scrollTop);
    const handleScroll = () => {
      const moving = window.pageYOffset || document.documentElement.scrollTop;

      setVisible(position > moving || isMenuDisplayed);
      setPosition(moving);

      positionStickyAd();
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [position, isMenuDisplayed]);

  const { switchTheme, isDarkTheme } = useThemeSwitcher();

  const navDisplayClass = visible ? 'visible' : 'hidden';
  const isDesktopMenuDisplayed = !isMenuDisplayed;

  return (
    <Container className={isDesktopMenuDisplayed ? navDisplayClass : ''}>
      <NavBar className={subNavLinks.length ? 'mod-high' : ''} id="nav-primary" data-testid="primary-navbar">
        <TopNav>
          {isMenuDisplayed ? (
            <CloseContainer onClick={hideMenu} type="button" aria-label={t('header.hideMenuLabel')}>
              <Close width={24} height={24} color="var(--text-color)" />
            </CloseContainer>
          ) : (
            <BurgerContainer onClick={displayMenu} type="button" aria-label={t('header.displayMenuLabel')}>
              <BurgerMenu width={20} height={12} color="var(--text-color)" />
            </BurgerContainer>
          )}
          <RootLogoLink className={isMenuDisplayed ? 'mod-fixed' : ''} href="/" title={t('header.altLogo')}>
            <Clubic width={82} height={44} />
          </RootLogoLink>
          <SearchForm action="/search" title={t('header.search')} className={isMenuDisplayed ? 'mod-hidden' : ''}>
            <SearchFormInput type="search" placeholder={t('header.searchPlaceHolder')} name="q" />
            <Validate>
              <Search width={24} height={24} color="var(--text-color)" />
            </Validate>
          </SearchForm>
          <DarkModeButton
            onClick={() => switchTheme()}
            type="button"
            role="switch"
            aria-checked={isDarkTheme}
            aria-label={t('header.darkModeLabel')}
            className={isMenuDisplayed ? '' : 'mod-hidden'}
          >
            <Moon width={15} height={17} color="var(--text-color-alt)" />
          </DarkModeButton>
          {sdkConfig.sitename === 'site-clubic' && (
            <UserIcon className={isMenuDisplayed ? 'mod-hidden' : ''}>
              <UserProfile icon={avatar} username={username} />
            </UserIcon>
          )}
          <NavLinks className={isMenuDisplayed ? 'mod-display' : ''} ref={navRef}>
            <MenuCta href="/telecharger/" className="mod-green mod-sm">
              {t('header.findASoftware')}
            </MenuCta>
            <PrimaryNavItems navLinks={navLinks} />
            {subNavLinks.length ? (
              <SubNav>
                <PrimarySubNav navLinks={subNavLinks} />
              </SubNav>
            ) : null}
          </NavLinks>
        </TopNav>
      </NavBar>
    </Container>
  );
};
